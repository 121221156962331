import "./Footer.css";
import {Facebook, YouTube, Instagram, Twitter} from "@mui/icons-material"

export default function Footer() {
  return (
    <>
        <div className="footer">
            <div className="footerWrapper">
                <footer>
                    <div className="row">
                        <div className="col">
                            <img src="/assets/logo/logo.png" alt="" className="logo11"/>
                            <p>SRINIVASA RAMANUJAN ACADEMY</p>
                            <p>An Institute Run & Guided by a Group of Educationist</p>
                            <p>(Under Srinivasa Ramanujan Educational Foundation. Reg No: KAM(M)/263/P/134 of 2017-2018)</p>
                        </div>
                        <div className="col">
                            <h3>OFFICE ADDRESS</h3>
                            <p>Sawkuchi, Lakhra-Rehabari Road,</p>
                            <p>Guwahati-40, Kamrup Metro,</p>
                            <p> Assam, India</p>
                            <p className="email-id">sracademyghy@gamil.com</p>
                            <p>+91 98643-89969 / 70029-56659</p>
                        </div>
                        <div className="col">
                            <h3>SOCIAL LINKS</h3>
                            <ul className="matIcomText">
                                <li><Facebook className="matIcon"/></li>
                                <br/>
                                <li><YouTube className="matIcon"/></li>
                                <br/>
                                <li><Instagram className="matIcon"/></li>
                                <br/>
                                <li><Twitter className="matIcon"/></li>
                            </ul>
                        </div>
                        <div className="col">
                            <h3>DIRECT CONTACT US</h3>
                            <div className="pank">
                                <p>Principal</p>
                                <p>Pankaj Sarma</p>
                                <p>+91 98643-89969</p>
                            </div>
                            <hr/>
                            <div className="bhup">
                            <p>Joint Director</p>
                            <p>Bhupen Sarma</p>
                            <p>+91 70029-56659</p>
                            </div>
                            
                        </div>
                    </div>
                </footer>
            </div>

        </div>

    </>
  )
}
