import "./Gallery.css";


// import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { EffectCoverflow, Pagination, Navigation } from "swiper";
// import slide_image_1 from "../../assets/photos/1.jpg";
// import slide_image_2 from "../../assets/photos/2.jpg";
// import slide_image_3 from "../../assets/photos/3.jpg";
// import slide_image_4 from "../../assets/photos/4.jpg";
// import slide_image_5 from "../../assets/photos/5.jpg";

import Img1 from '../../assets/photo/1.jpg';
import Img2 from '../../assets/photo/2.jpg';
import Img3 from '../../assets/photo/3.JPG';
import Img4 from '../../assets/photo/4.JPG';
import Img5 from '../../assets/photo/5.JPG';
import Img6 from '../../assets/photo/6.jpg';
import Img7 from '../../assets/photo/7.jpg';
import Img8 from '../../assets/photo/8.jpg';
import Img9 from '../../assets/photo/9.jpg';
import Img10 from '../../assets/photo/10.jpg';
import Img11 from '../../assets/photo/11.jpg';
import Img12 from '../../assets/photo/12.jpg';
import Img13 from '../../assets/photo/13.jpg';
import Img14 from '../../assets/photo/14.jpg';
import Img15 from '../../assets/photo/15.jpg';
import Img16 from '../../assets/photo/16.jpg';
import Img17 from '../../assets/photo/17.jpg';
import Img18 from '../../assets/photo/18.jpg';
import Img19 from '../../assets/photo/19.jpg';
import { useState } from "react";
import {Close} from "@mui/icons-material";


export default function Gallery() {


      let data = [
        {
          id : 1,
          imgSrc : Img1,
        },
        {
          id : 2,
          imgSrc : Img2,
        },
        {
          id : 3,
          imgSrc : Img3,
        },
        {
          id : 4,
          imgSrc : Img4,
        },
        {
          id : 5,
          imgSrc : Img5,
        },
        {
          id : 6,
          imgSrc : Img6,
        },
        {
          id : 7,
          imgSrc : Img7,
        },
        {
          id : 8,
          imgSrc : Img8,
        },
        {
          id : 9,
          imgSrc : Img9,
        },
        {
          id : 10,
          imgSrc : Img10,
        },
        {
          id : 11,
          imgSrc : Img11,
        },
        {
          id : 12,
          imgSrc : Img12,
        },
        {
          id : 13,
          imgSrc : Img13,
        },
        {
          id : 14,
          imgSrc : Img14,
        },
        {
          id : 15,
          imgSrc : Img15,
        },
        {
          id : 16,
          imgSrc : Img16,
        },
        {
          id : 17,
          imgSrc : Img17,
        },
        {
          id : 18,
          imgSrc : Img18,
        },
        {
          id : 19,
          imgSrc : Img19,
        },
    ]
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) =>{
      setTempImgSrc(imgSrc);
      setModel(true);
    }


  return (
    <>
      <div className="gallery">

        {/* <h3 className="galleryT">Some photos of SRINIVASA RAMANUJAN ACADEMY</h3> */}
        <h3 className="galleryT">Gallery</h3>


        <div className={model? "model open" : "model"}>
          <img src={tempimgSrc} alt=""/>
          <Close onClick={()=>setModel(false)}/>
        </div>

        <div className="galler">
          {data.map((item, index)=>{
            return(
                <div className="pics" key={index} onClick={()=>getImg(item.imgSrc)}>
                  <img src={item.imgSrc} style={{width: '100%'}} alt=""/>
                </div>
            )
          })}
        </div>



        {/* <div
          id="carouselExampleAutoplaying"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="/assets/photos/1.jpg" class="d-block w-100" alt="..." id="pho"/>
            </div>
            <div class="carousel-item">
              <img src="/assets/photos/2.jpg" class="d-block w-100" alt="..." id="pho"/>
            </div>
            <div class="carousel-item">
              <img src="/assets/photos/3.jpg" class="d-block w-100" alt="..." id="pho"/>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> */}


        {/* <div className="container">
            <h1 className="heading">Gallery</h1>
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={'auto'}
              coverflowEffect={
                {
                  rotate : 0,
                  stretch :0,
                  depth : 100,
                  modifier : 2.5,
                }
              }
              pagination={{el: '.swiper-pagination', clickable: true}}
              navigation={{
                nextEl:'.swiper-button-next',
                prevEl:'.swiper-button-prev',
                clickable:true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
              <SwiperSlide>
                <img src={slide_image_1} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_2} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_3} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_4} alt=""/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_5} alt=""/>
              </SwiperSlide>

              <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>

        </div> */}


      </div>
    </>
  );
}
