import "./Topbar.css";
import {AddIcCall, Email, AddLocationAlt} from "@mui/icons-material";

export default function Topbar() {
  return (
    <>
        <div className="topbar">
            <div className="topbarWrapper">

                <div className="topbarLeft">
                    <img className="topbarLeftImg" src="assets/logo/logo.png" alt=""/>
                </div>

                <div className="topbarCenter">
                    <span className="collegeName"><span className="style">S</span>RINIVASA  <span className="style">R</span>AMANUJAN  <span className="style">A</span>CADEMY</span>
                    <br/>
                    <span className="collegeNameDetails">An Institute Run & Guided by a Group of Educationist</span>
                    <br/>
                    <span className="collegeNameReg">(Under Srinivasa Ramanujan Educational Foundation. Reg No: KAM(M)/263/P/134 of 2017-2018)</span>
                </div>

                <div className="topbarRight">
                    <div className="iconContainer">
                        <AddIcCall className="icon" />
                        <span className="iconDetails">+91 98643-89969 / 70029-56659</span>
                    </div> 
                    <div className="iconContainer">
                        <Email className="icon"/>
                        <span className="iconDetails">sracademyghy@gamil.com</span>
                    </div> 
                    <div className="iconContainer">
                        <AddLocationAlt className="icon"/>
                        <span className="iconDetails">Sawkuchi,Lakhra-Rehabari Road,</span><br/>
                        <span className="iconDetails">Kamrup,Guwahati-40,Assam</span>
                    </div> 
                </div>
            </div>
        </div>
    </>
  )
}
