import "./Home.scss";
import "./Home.css";
import sra from '../../../src/assets/video/sra_v.mp4';
import video1 from '../../../src/assets/video/video1.mp4';


export default function Home() {

  return (
    <>
      <section className="home">
        <div className="overlay"></div>
        <video src={sra} muted autoPlay loop type="video/mp4"></video>

        <div className="homeContent container">
          <div className="textDiv">

            <span className="smallerText">
              Welcome to
            </span>

            <h1 className="homeTitle">
              SRINIVASA RAMANUJAN ACADEMY
            </h1>

          </div>

          <div className="cardDiv">

          <label>Academics</label>

            <div className="destinationInput">
              
              <div className="input">
                <span className="inputDet">SRINIVASA RAMANUJAN ACADEMY</span><br/>
                <span className="inputDet">Junior College</span><br/>
                <span className="inputDet">AHSEC / CBSE</span>
              </div>

              <div className="input">
                <span className="inputDet">SRINIVASA RAMANUJAN ACADEMY</span><br/>
                <span className="inputDet">English School</span><br/>
                <span className="inputDet">SEBA</span>
              </div>


              <div className="input">
                <span className="inputDet">SRINIVASA RAMANUJAN ACADEMY</span><br/>
                <span className="inputDet">Assamese School</span><br/>
                <span className="inputDet">SEBA</span>
              </div>

            </div>

            <div className="inquiry flex">
              <span>Admission Enquiry </span>
              <span>+91 98643-89969</span>
            </div>

          </div>

        </div>
      </section>

      <br/>

      <div className="row">
        <h4 className="rowtext">Welcome to SRINIVASA RAMANUJAN ACADEMY</h4>
        <p className="rowtext1">The SRINIVASA RAMANUJAN ACADEMY  in Guwahati, Assam  offers a 360-degree learning environment to its students and is rated as one of the best schools in Guwahati, Assam. The school follows an integrated SEBA Syllabus which is equipped with a micro-schedule that defines preparation on an hour-to-hour basis and acts as an added advantage to students. Further, SRINIVASA RAMANUJAN ACADEMY follow the best and top teaching methodologies to prepare students for JEE and NEET. These methods offer the best possible foundation and assistance that help students perform exceptionally well in National competitive exams like Olympiad, JEE, NEET and others.</p>
        <div className="button">
          <button className="primary-btn">
            Pre Primary <i className="fa fa-long-arrow-alt-right"></i>
          </button>
          <button className="primary-btn">
            Primary <i className="fa fa-long-arrow-alt-right"></i>
          </button>
          <button className="primary-btn">
            High School <i className="fa fa-long-arrow-alt-right"></i>
          </button>
          <button className="primary-btn">
            Junior College <i className="fa fa-long-arrow-alt-right"></i>
          </button>
        </div>

      </div>

      <br/>

      <div className="su">
        <h2 className="sutext">Our Success Rate</h2>
        <video src={video1} muted autoPlay loop type="video/mp4" className="suc"></video>
      </div>


      <br/>


      <div className="row">
        <h4 className="rowtext">Our VISION</h4>
        <p className="rowtext1">The SRINIVASA RAMANUJAN ACADEMY  in Guwahati, Assam  offers a 360-degree learning environment to its students and is rated as one of the best schools in Guwahati, Assam. The school follows an integrated SEBA Syllabus which is equipped with a micro-schedule that defines preparation on an hour-to-hour basis and acts as an added advantage to students. Further, SRINIVASA RAMANUJAN ACADEMY follow the best and top teaching methodologies to prepare students for JEE and NEET. These methods offer the best possible foundation and assistance that help students perform exceptionally well in National competitive exams like Olympiad, JEE, NEET and others.</p>

      </div>

      <br/>


      <section className="home">
        <div className="overlay"></div>
        <video src={sra} muted autoPlay loop type="video/mp4" className="vi"></video>

        <div className="homeContent container">
          <div className="textDiv">

            <span className="smallerText1">
            Reasons To Choose SRINIVASA RAMANUJAN ACADEMY
            </span>

            <br/>
            <br/>
            <br/>

            <h1 className="homeTitle">
            Low fee structure
            </h1>
            <br/>
            <h1 className="homeTitle">
            Quality education 
            </h1>
            <br/>
            <h1 className="homeTitle">
            Highly Educated Faculty
            </h1>

          </div>

          {/* <div className="cardDiv">

          <label>Academics</label>

            <div className="destinationInput">
              
              <div className="input">
                <span className="inputDet">SRINIVASA RAMANUJAN ACADEMY</span><br/>
                <span className="inputDet">Junior College</span><br/>
                <span className="inputDet">SEBA</span>
              </div>

              <div className="input">
                <span className="inputDet">SRINIVASA RAMANUJAN ACADEMY</span><br/>
                <span className="inputDet">English School</span><br/>
                <span className="inputDet">SEBA</span>
              </div>


              <div className="input">
                <span className="inputDet">SRINIVASA RAMANUJAN ACADEMY</span><br/>
                <span className="inputDet">Assamese School</span><br/>
                <span className="inputDet">SEBA</span>
              </div>

            </div>

            <div className="inquiry flex">
              <span>Admission Enquiry </span>
              <span>+91 60020-66808</span>
            </div>

          </div> */}

        </div>
      </section>

      
    </>
  );
}
