import "./Admission.css";
import { Link } from "react-router-dom";

export default function Admission() {
  return (
    <>
        <div className="admission">
            
            <div className="admissionWrapper">
                <div className="admi">
                    <span className="adm">ADMISSION PROCEDURE</span>
                </div>
                <div className="adm1">
                    <span>THE COLLEGE ADOPTS FOLLOWING ADMISSION PROCEDURES</span>
                </div>
                <div className="adm2">
                    <ol className="adm3">
                        <li>Admission in HS 1st year classes for fresh students will be made purely on merit basis. The in-house students duly passed in the HS 1st year examination will automatically qualify for admission in the 2nd year.</li>
                        <li>Admission forms and prospectus can be obtained from the office of the College against a payment of Rs. 300/- (rupees one hundred) only.</li>
                        <li>Admission is made strictly in order of merit and is governed by the College admission rules. Principal’s decision regarding admission is final.</li>
                        <li>Under any circumstances Admission Fee will not be reimbursed. If anyone seeks TC in between a session, he or she has to pay the remaining tuition fee for the whole year.</li>
                        <li>Admission forms should be submitted along with following testimonials within seven days from the date of issue of forms unless otherwise notified.
                            <ul>
                                <li>Self attested copies of marks-sheet, Certificates and Admit Cards, Registration Card of last qualifying examination.</li>
                                <li>Two copies of recent passport size photographs.</li>
                                <li>Self-Attested copies of caste certificate.</li>
                                <li>Original migration certificate for CBSE/Other Board Students. (Students are required to produce the all original documents attached with the admission form during the time of admission)</li>
                            </ul>
                        </li>
                        <li>Eligible candidates will be enlisted after scrutiny and duly notified on the College notice board for admission. Registration forms of Assam Higher Secondary Education Council will be issued to the enlisted candidates only at the time of admission or later on. The filled up registration forms must be submitted to the College within seven days from the date of distribution with the prescribed registration fees.</li>
                        <li>Any pressure or interference from any quarter for admission will automatically disqualify the claims of an admission seeker.</li>
                        <li>No student will be considered for promotion in the unit test examination on medical or other extra-ordinary grounds unless he/she clears all preceding examinations conducted by the College/department during that session.</li>
                    </ol>
                </div>

                <div className="adm4">
                    <span>ATTENDANCE RULES</span>
                </div>
                <div className="adm5">
                    <span>Students not attending at least 90% of classes held will not be allowed to appear in the final examination conducted by AHSEC</span>
                </div>
            
            <div className="adm6">
                <soan>Apply here</soan>
            </div>

            <div className="admClas">


<div className="sraac">
      <h2 className="experimentTag">SRINIVASA RAMANUJAN ACADEMY OFFER</h2>
      <div className="experimentList">

        
        <div className="experimentItem">
            <a href="https://studentcorner.darpan.ahseconline.in/login" className="expLink">
            <img src="/assets/photos/6.jpg" alt="" className="experimentImg"/>
            <h3 className="experimentT">SRINIVASA RAMANUJAN ACADEMY</h3>
            <h3 className="experimentT">Junior College</h3>
            <h3 className="experimentT">Science, Arts & Commarce</h3>
            <h3 className="experimentT">HS 1st & 2nd Year</h3>
            </a>
        </div>

        {/* <div className="experimentItem">
            <a href="" className="expLink">
            <img src="/assets/photos/1.jpg" alt="" className="experimentImg"/>
            <h3 className="experimentT">SRINIVASA RAMANUJAN ACADEMY</h3>
            <h3 className="experimentT">Junior College</h3>
            <h3 className="experimentT">Arts</h3>
            </a>
        </div>

        <div className="experimentItem">
            <a href="" className="expLink">
            <img src="/assets/photos/2.jpg" alt="" className="experimentImg"/>
            <h3 className="experimentT">SRINIVASA RAMANUJAN ACADEMY</h3>
            <h3 className="experimentT">Junior College</h3>
            <h3 className="experimentT">Commarce</h3>
            </a>
        </div> */}

        <div className="experimentItem">
            <Link to="/aschool" className="expLink">
            <img src="/assets/photos/3.jpg" alt="" className="experimentImg"/>
            <h3 className="experimentT">SRINIVASA RAMANUJAN ACADEMY</h3>
            <h3 className="experimentT">Assamese School</h3>
            <h3 className="experimentT">Class 1 to Class X</h3>
            <h3 className="experimentT">Medium Assamese</h3>
            </Link>
        </div>

        <div className="experimentItem">
            <Link to="/eschool" className="expLink">
            <img src="/assets/photos/4.jpg" alt="" className="experimentImg"/>
            <h3 className="experimentT">SRINIVASA RAMANUJAN ACADEMY </h3>
            <h3 className="experimentT">English School</h3>
            <h3 className="experimentT">Class KG to Class X</h3>
            <h3 className="experimentT">Medium English</h3>
            </Link>
        </div>

      </div>
    </div>


            </div>
            </div>
        </div>
        
    </>
  )
}
