import "./Contact.css";

export default function Contact() {
  return (
    <>
    
    <div className="contactUs">
      <h2 className="contactText">Contact Us</h2>
      
      <div className="rb">
        <img src="/assets/photos/7.jpg" alt="" className="contactImg"/><br/><br/>
          <span className="contactName">SRINIVASA RAMANUJAN ACADEMY</span><br/><br/>
          <span className="contactDegP">Junior College</span><br/><br/>
          <span className="contactDegD">Contact Details</span><br/><br/>
          <span className="contactDeg">
            <ul className="contactList">
                <li className="contactListItem"><span className="contactListItemF">Office Address: </span>  Sawkuchi, Lakhra-Rehabari Road,Guwahati-40, Kamrup(M), Assam

</li>
                <li className="contactListItem"><span className="contactListItemF">Phone: </span> +91 98643-89969</li>
                <li className="contactListItem"><span className="contactListItemF">Email: </span> sracademyghy@gamil.com</li>
              </ul>
          </span>
      </div>
      <br/>
      <div className="jd">
        <img src="/assets/photos/8.jpg" alt="" className="contactImg"/><br/><br/>
          <span className="contactName">SRINIVASA RAMANUJAN ACADEMY</span><br/><br/>
          <span className="contactDegP">English School</span><br/><br/>
          <span className="contactDegD">Contact Details</span><br/><br/>
          <span className="contactDeg">
              <ul className="contactList">
                  <li className="contactListItem"><span className="contactListItemF">Office Address: </span> Sawkuchi, Lakhra-Rehabari Road,Guwahati-40, Kamrup(M), Assam</li>
                  <li className="contactListItem"><span className="contactListItemF">Phone: </span> +91 98643-89969</li>
                  <li className="contactListItem"><span className="contactListItemF">Email: </span> sracademyghy@gamil.com</li>
              </ul>
          </span>
      </div>
      <br/>
      <div className="sb">
        <img src="/assets/photos/1.jpg" alt="" className="contactImg"/><br/><br/>
          <span className="contactName">SRINIVASA RAMANUJAN ACADEMY</span><br/><br/>
          <span className="contactDegP">Assamese School</span><br/><br/>
          <span className="contactDegD">Contact Details</span><br/><br/>
          <span className="contactDeg">
          <ul className="contactList">
                <li className="contactListItem"><span className="contactListItemF">Office Address: </span> Sawkuchi, Lakhra-Rehabari Road,Guwahati-40, Kamrup(M), Assam</li>
                <li className="contactListItem"><span className="contactListItemF">Phone: </span> +91 98643-89969</li>
                <li className="contactListItem"><span className="contactListItemF">Email: </span> sracademyghy@gamil.com</li>
              </ul>
          </span>
      </div>
      <br/>
      <div className="ar">
        <img src="/assets/faculty/ps.jpg" alt="" className="contactImg"/><br/><br/>
          <span className="contactName">Pankaj Sarma</span><br/><br/>
          <span className="contactDegP">Principal</span><br/><br/>
          <span className="contactDegD">Contact Details</span><br/><br/>
          <span className="contactDeg">
          <ul className="contactList">
                <li className="contactListItem"><span className="contactListItemF">Office Address: </span> Sawkuchi, Lakhra-Rehabari Road,Guwahati-40, Kamrup(M), Assam </li>
                <li className="contactListItem"><span className="contactListItemF">Phone: </span> +91 98643-89969</li>
                <li className="contactListItem"><span className="contactListItemF">Email: </span> 	sracademyghy@gamil.com</li>
              </ul>
          </span>
      </div>
      <br/>
      <div className="nk">
        <img src="/assets/faculty/bs.png" alt="" className="contactImg"/><br/><br/>
          <span className="contactName">Bhupen Sarma</span><br/><br/>
          <span className="contactDegP">Joint Director</span><br/><br/>
          <span className="contactDegD">Contact Details</span><br/><br/>
          <span className="contactDeg">
          <ul className="contactList">
                <li className="contactListItem"><span className="contactListItemF">Office Address: </span>  Sawkuchi, Lakhra-Rehabari Road,Guwahati-40, Kamrup(M), Assam</li>
                <li className="contactListItem"><span className="contactListItemF">Phone: </span> +91 70029-56659</li>
                <li className="contactListItem"><span className="contactListItemF">Email: </span> sracademyghy@gamil.com</li>
              </ul>
          </span>
      </div>
      <br/>
      
    </div>

    </>
  )
}
