import "./Aschool.css";
const PDF_FILE_URL = 'http://13.126.44.161/Admission_Form.pdf'

export default function Aschool() {
  const downloadFileAtURL = (url) =>{
        const fileName = url.split('/').pop();
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
  }
  return (
    <>
      <div className="aschool">
        <span className="aschoolName">Welcome to SRINIVASA RAMANUJAN ACADEMY Assamese School</span><br/>
        <span className="eschooldetails">For Admission Please Download The PDF & Fill up the form & submit to the SRA office</span>

        <div className="pdf">
            <span className="admis">Admission Form</span>
            
            <div className="pdfButton">
                <button type="button" class="btn btn-outline-success" onClick={()=>{downloadFileAtURL(PDF_FILE_URL)}}>Download here</button>
            </div>
            
          </div>

      </div>
    </>
  )
}
