import "./About.css";

export default function About() {
  return (
    <>
      <div className="about">
        <div className="aboutWrapper">
          <div className="about1">
            <h3>ABOUT OUR ACADEMY</h3>
            <p>
            An educational institution is a building surrounded by walls with future inside. The prime objective of an educational institution is to nurture, facilitate and ignite an aspirant to find the right path to reach the right destination. Since time immemorial there has been the demand for quality education. The present global academic scenario makes one feel that Private Sector Educational Institutions are serving in a proper tone in maintaining the demand of quality education. There are numerous reasons for which private institutions have been emerging enormously across the world. The prime reason is the limitations of academic and infrastructural facilities, though not all, provided by a governmental institution in any quarter.
            </p>
            <br/>
            <p>
            There are many private undertaking schools and colleges in the locality of greater Guwahati. These educational institutes have been serving the aspirations of the local students of this part to some extent. But sometime it is felt that they are not sufficient to fulfill all the hopes and beliefs of the students and guardians as well for which they are aspired for. The institute of SRINIVASA RAMANUJAN ACADEMY is set up as a means to meet the educational needs of this area. SRINIVASA RAMANUJAN ACADEMY has recently established out of the need to provide an alternative high quality educational curriculum that focuses on education of the child with a smaller teacher student ratio and comprising Arts,Science and Commerce under Assam Higher Secondary Education Council (AHSEC). As a part of this enlightening project, this institute is dedicated to serve the causes of the society by imbibing the ethos along with the institutional routine. As such, the institute is established where all the parts of educational materials are being stored together. All quarters aspiring for an excellent formal education can trust and believe this institution for future scope of children where they can achieve every sphere of education without hampering their time and economy.
            </p>
            <br/>
            <p>
            SRINIVASA RAMANUJAN ACADEMY seeks blessings and good wishes from all stakeholders in developing this temple of education.
            </p>
            <br/>
          </div>

          <div className="about1">
            <h3>AIMS & OBJECTIVE</h3>
            <br/>
            <p>
            Each and every child has inner talent and skill, which remains unused due to lack of proper guidance, lack of adequate facilities, laziness, fear, shyness, etc. Srinivasa Ramanujan Academy intends to help the children in eradicating all the drawbacks by bringing forth the hidden talent through various modern activities and equipments and help them in facing the upcoming challenges with boldness and courage. Moreover, it aims at imparting proper education so that a student after leaving their school and college campus can keep his feet in professional and competitive world confidently.
            </p>
          </div>

          <div className="about1">
            <h3>LOCATION</h3>
            <p>The institute is situated at Sawkuchi, Jonaki Nagar, Kamrup (M) , Assam Near Budhbariya Bazar.</p>
          </div>

          <div className="about1">
              <h3>BUILDING</h3>
              <p>The college building is setup with one RCC building and one assam type house having well furnished class rooms,office rooms,science laboratory,teachers staff room,library etc...Both the campus & class rooms are fully under CCTV Surveillance.</p>
          </div>

          <div className="about1">
              <h3>ACADEMIC YEAR</h3>
              <p>The academic year for XI and XII are run by AHSEC’s academic period.</p>
          </div>

          <div className="about1">
            <h3>SYLLABUS</h3>
            <p>Class XI and XII comprising Arts, Science and Commerce follows the prescribed syllabus of Assam Higher Secondary Education Council (AHSEC).</p>
          </div>

          <div className="about1">
            <h3>MEDIUM OF INSTRUCTION</h3>
            <p>"English and Assamese" is the medium of instruction of the institute.</p>
          </div>

          <div className="about1">
            <h3>TEACHING, LEARNING & EVALUATION</h3>
            <p>
            The college seeks to supplement traditional classroom teaching with learning which implies students’ active interaction in the teaching learning participation in seminar, tutorials, fieldworks and group-discussions, etc. Teachers in each department coordinate and specify positive and negative experiences in different programs, progress of the syllabus as per internal plans and when necessary. They suggest measures for methodological/tactical innovations in teaching and learning.
            </p>
          </div>

          <div className="about1">
            <h3>REMEDIAL CLASSES</h3>
            <p>The College has the plan to introduce remedial classes for the weak students from the very first session. With the assistance of various experienced professors from various colleges the Remedial classes can be introduced primarily to support weak students in acquiring necessary academic skills. However, with a view to dispelling sense of separateness / social distinctness in student, the college will encourage even general students who are otherwise economically backward, to attend Remedial classes . Remedial classes, be it mentioned, is distinct from the conventional classes. In this type of classes it concentrates on student-centered, examination-oriented approach. Tutorials, seminars, etc. are parts of the remedial classes.</p>
          </div>

          <div className="about1">
            <h3>SYLLABUS DESIGN</h3>
            <p>
            With a view to bringing more accountability in teaching and bringing syllabus content in each class is split up and unitized thereby promising an exhaustive and uniform treatment of split up modules of a subject. Accordingly, the time-bound split-up syllabus is notified by all the departments that indicate the portion of the syllabus / course contents/ chapters which are to be the modules for particular terminal/ unit test/ test examinations.
            </p>
          </div>

          <div className="about1">
            <h3>EVALUATION SYSTEM</h3>
            <p>Continuous evaluation is carried out in the form of unit tests and other tests of the students in order to promote students’ perception in the subject are and locate the weak links/ strong points of particular students. Unit tests of HS are conducted as per the syllabus breakup notified by each department. The time schedule of different departmental or other unit tests and sessional examinations will be notified otherwise by the College. NB: I. Final Examination of the aforesaid classes will be held as per notification of AHSEC, Guwahati.</p>
          </div>

          <div className="about1">
            <h3>SCIENCE LAB</h3>
            <p>The college is well equipped with a Sophisticated science laboratory separately designed for physics, chemistry and biology sections.</p>
          </div>

          <div className="about1">
            <h3>REGULARITY/ATTENDANCE/ LEAVE GRANT</h3>
            <p>
            90% attendance is must for a student. Regularity and punctuality shall also be taken into consideration in the process of promotion to the next class. Students may take leave in case of some emergency or some unavoidable circumstance for which proper intimation should be forwarded to the authority. The half-day leave will not be entertained.
            </p>
          </div>

          <div className="about1">
            <h3>PROSPECTUS</h3>
            <p>The college prospectus will act as true guide for the students as well as their parents/guardians in all respects. All information such as admission, uniform, rules & regulations etc. are stated there in. Therefore, the same should be followed sincerely and strictly.</p>
          </div>

          <div className="about1">
            <h3>ADVENTURE SOCIETY/ FIELD TRIP</h3>
            <p>The college has Adventure society to motivate and boost the morale of the students apart from scholastic curriculum.</p>
          </div>

          <div className="about1">
            <h3>COLLEGE FUNCTION</h3>
            <p>An annual college day function & Freshmen Social is celebrated every year around August/September.</p>
          </div>

          <div className="about1">
            <h3>TRANSPORT FACILITY</h3>
            <p>The college provide the transport facility for the college students against the monthly fixed transport fees. The transport fees will be charged as per the distance for 12 months of the session. The transport/college van operate on fixed routes within Greater Guwahati.</p>
          </div>

          <div className="about1">
            <h3>P.G. FOR BOYS & GIRLS</h3>
            <p>The college provides accomodation of paying guest for boys and girls. Qualified warden along with teachers are engaged to attend to all needs of the students. A medical doctor is on call to provide medical care in case of indispositions not requiring hospitalization. The P.G. has a modern and well equipped bedroom, kitchen and bathroom and balanced and nutritions vegetarian and non-vegetarian snacks and meals.</p>
          </div>

          <div className="about1">
            <h3>COLLEGE DAYS AND TIMINGS</h3>
            <p>The college has six days week(Monday- Saturday) for all classes.
Timing:
Classes XI &XII : 9.00am to 3.15 pm(with a recess after the 4th period)</p>
          </div>

          <div className="about1">
            <h3>MOTIVATION & ORIENTATION CLASSES</h3>
            <p>Development of personality is a pre-condition for a successful performance to enter the world of competition. The college provides scope for extra-curricular activities for promoting the zeal of togetherness and community service among the students. The college has motivational and orientation classes arrangement for students through the help by the reputed University and colleges professors and other reputed Resource Persons.</p>
          </div>

          <div className="about1">
            <h3>FEEDBACK SYSTEM AND ASSESSMENT OF TEACHING</h3>
            <p>Teaching is evaluated by the students through feedback as a mechanism for smoothing up teaching-learning process and bringing reformative measures wherever necessary. The SRA, in order to upgrade the quality and result oriented education, has planned to accelerate continuous assessment of teaching by feedback system.

</p>
          </div>

          <div className="about1">
            <h3>STUDENT LIFE, CO-CURRICULAR AND OTHER ACTIVITIES</h3>
            <p>The SRA has planned a mechanism to encourage the students to participate actively in co-curricular activities through active participation in games and sports and intellectual exercise like participation in seminar, cultural competitions and interactive programs. The college believes in engaging in a wide variety of extracurricular activities to help create a well rounded confident and culturally and socially alert student. Among the range of activities available to students debating, dance, music, academic workshops, seminars, cultural and academic/Educational tours are worth mentioning.</p>
          </div>

          <div className="about1">
            <h3>SPORTS ACTIVITIES</h3>
            <p>The SRA Plans to organize Annual Day in November/December every year. Students will be motivated and trained up for participating in Indoor and Outdoor Games, Debate and Quiz, Literary and Cultural competitions.</p>
          </div>

          <div className="about1">
            <h3>STUDENT MONITOR</h3>
            <p>From H.S. 2nd year there will be a girl and boy as class monitor to have the opportunity of serving as its office bearers for the students and teachers. Class Monitor selected annually and directly, will be responsible for conducting various programs relating to extra-curricular and social activities throughout the year.</p>
          </div>

          <div className="about1">
            <h3>INFORMATION & CAREER GUIDANCE</h3>
            <p>The college has set up the Information & Career Guidance Cell. This cell is to guide students about career planning, job prospects, entrepreneurship, etc. Personality development, quantitative aptitude enhancement, imparting skills in facing job interview,etc. will be focal points the cell will look into.</p>
          </div>

        </div>

      </div>
    </>
  )
}
