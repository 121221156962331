import "./Courses.css";

export default function Courses() {
  return (
    <>
      <div className="course">
        <div className="mess">
          <img className="messPS" src="assets/faculty/ps.jpg" alt="" />
          <br />
          <br />
          <p>Principal</p>
          <p>Courses offered by SRINIVASA RAMANUJAN ACADEMY </p>
        </div>

        <div className="college">
          <h3 className="collegeT">
            SRINIVASA RAMANUJAN ACADEMY JUNIOR COLLEGE
          </h3>

          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Science
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <strong>
                    H.S 1st Year & H.S 2nd Year with Assamese, English, Alt English, Maths, Physics, Chemistry, Biology, 
                    Computer Science
                  </strong>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Arts
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <strong>
                    H.S 1st Year & H.S 2nd Year with Assamese, English, Alt English, Economics, Political Science, Logic & Philosophy, Education, Computer, Swadesh Adhyayan
                  </strong>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Commarce
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <strong>
                    H.S 1st Year & H.S 2nd Year with Assamese, English, Alt English, Business Studies, Accoundency, Banking, Economices, Computer
                  </strong>
                </div>
              </div>
            </div>
          </div>

        </div>

         

        <div className="school">
          <h3 className="collegeT">SRINIVASA RAMANUJAN ACADEMY SCHOOL</h3>

          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Enghish School
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong>Class KG to Class X</strong>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Assamese School
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong>Ankur to Class X</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
