

export const teacherCollege = [
    {
        id: 1,
        name: "Kulendra Deka",
        deg: "PGT",
        que: "M.Sc",
        exp: "26 years Experience",
        img: "/assets/faculty/Kulendra_Deka.png",
    },
    {
        id: 2,
        name: "Kamal Sarma",
        deg: "PGT",
        que: "B.Tech (ECE), MBA (HR)",
        exp: "10 years Experience",
        img: "/assets/faculty/Kamal_Sarma.png",
    },
    {
        id: 3,
        name: "Mimi Bairagi",
        deg: "PGT, Commerce",
        que: "M.Com (Accounting & Finance)",
        exp: "8 years Experience",
        img: "/assets/faculty/Mimi_Bairagi-removebg-preview.png",
    },
    {
        id: 4,
        name: "Punam Sarma",
        deg: "PGT",
        que: "M.Sc, B.Ed",
        exp: "1 year Experience",
        img: "/assets/faculty/Punam_Sarma.png",
    },
    {
        id: 5,
        name: "Sangita Roy",
        deg: "PGT",
        que: "M.Com (Accountancy & Finance)",
        exp: "5 years Experience",
        img: "/assets/faculty/Sangita_Roy.png",
    },
    {
        id: 6,
        name: "Banashree Kakoti",
        deg: "PGT",
        que: "M.A (Political Science)",
        exp: "6 Yesrs Experience",
        img: "/assets/faculty/Banashree_kakoti.png",
    },
    {
        id: 7,
        name: "Pratibha Deka",
        deg: "PGT (Post Graduate Teacher)",
        que: "M.A (Assamese), B.Ed, Diploma in Bodo Language, B.Music in Satriya Dance, DICA in Computer Application",
        exp: "18 years Experience",
        img: "/assets/faculty/Pratibha_Deka.png",
    },
    {
        id: 8,
        name: "Gitu Mani Medhi",
        deg: "PGT",
        que: "M.A, B.Ed",
        exp: "6 years Experience",
        img: "/assets/faculty/Gitu_Mani_Medhi.png",
    },
    {
        id: 9,
        name: "Tapoja Sengupta",
        deg: "PGT (English)",
        que: "M.A, B.Ed",
        exp: "7 years Experience",
        img: "/assets/faculty/TAPOJA_SENGUPTA.png",
    },
    {
        id: 10,
        name: "Runa Sonowal",
        deg: "PGT (English)",
        que: "M.A (English Literature)",
        exp: "7 years Experience",
        img: "/assets/faculty/Runa_Sonowal.png",
    },
    {
        id: 11,
        name: "Archana Sarma",
        deg: "PGT",
        que: "M.A (Economice), B.Ed, TET(ME)",
        exp: "5 years Experience",
        img: "/assets/faculty/Archana_sarma.png",
    },
    {
        id: 12,
        name: "Dhiruma Bordoloi",
        deg: "PGT",
        que: "M.A (Assamese), NET, D.El.Ed",
        exp: "5 years Experience",
        img: "/assets/faculty/Dhiruma_Bordoloi.png",
    },
    {
        id: 13,
        name: "Barnali Deka",
        deg: "PGT",
        que: "M.A (Philosophy), NET Qualified",
        exp: "6 years Experience",
        img: "/assets/faculty/Barnali_Deka.png",
    },
    {
        id: 14,
        name: "Bijit Kumar Deka",
        deg: "PGT (Chemistry)",
        que: "M.Sc, B.Ed",
        exp: "4 years Experience",
        img: "/assets/faculty/Bijit_Kumar_Deka.png",
    },
    {
        id: 15,
        name: "Saini Bhattacharjee",
        deg: "PGT",
        que: "M.Sc(Zoology) GOLD MEDALIST",
        exp: "Fresher",
        img: "/assets/faculty/Saini_Bhattacharjee.png",
    },

];


export const teacherSchool = [
    {
        id: 1,
        name: "Bhanita Sarma",
        deg: "PRT",
        que: "M.A (Sanskrit), Visharad in Hindi",
        exp: "8 years Experience",
        img: "/assets/faculty/Bhanita_Sarma.png",
    },
    {
        id: 2,
        name: "Kiran Kalita",
        deg: "PRT",
        que: "M.A (Assamese, Del Ed)",
        exp: "3 years Experience",
        img: "/assets/faculty/KiranKalita.png",
    },
    {
        id: 3,
        name: "Dhritishree Roy Choudhury",
        deg: "PRT",
        que: "M.A, D.El.Ed",
        exp: "12 years Experience",
        img: "/assets/faculty/DHRITISHREE_ROY.png",
    },
    {
        id: 4,
        name: "Mithu  Varsha  Pathak   ",
        deg: "PRT",
        que: "M.A, D.El.Ed",
        exp: "16 years Experience",
        img: "/assets/faculty/Mithu_Varsha_Pathak.png",
    },
    {
        id: 5,
        name: "Lovely Lahon Phukan",
        deg: "PRT",
        que: "B.A",
        exp: "12 year Experience",
        img: "/assets/faculty/Lovely Lahon Phukan.png",
    },
    {
        id: 6,
        name: "Farhin Naaz",
        deg: "PRT",
        que: "M.Com (Pursuing)",
        exp: "2.5 years Experience",
        img: "/assets/faculty/Farhin_Naaz.png",
    },
    {
        id: 7,
        name: "Jimi Saikia",
        deg: "PRT",
        que: "",
        exp: "",
        img: "/assets/faculty/.png",
    },
    {
        id: 8,
        name: "Sangita Roy",
        deg: "PRT",
        que: "M.Com (Accountancy & Finance)",
        exp: "5 years Experience",
        img: "/assets/faculty/Sangita_Roy.png",
    },
    {
        id: 9,
        name: "Dhanjit Bezbaruah",
        deg: "PRT",
        que: "M.Com",
        exp: "Fresher",
        img: "/assets/faculty/Dhanjit_Bezbaruah.png",
    },
    {
        id: 10,
        name: "Shikha Rani Das",
        deg: "PRT",
        que: "M.Sc",
        exp: "2 Years Experience",
        img: "/assets/faculty/Shikha Rani Das.png",
    },
    {
        id: 11,
        name: "Purabi Debi",
        deg: "PRT",
        que: "",
        exp: "",
        img: "/assets/faculty/Purabi Debi.png",
    },
    {
        id: 12,
        name: "Sabita Barpujari",
        deg: "PRT",
        que: "",
        exp: "",
        img: "/assets/faculty/Sabita Barpujari.png",
    },
    {
        id: 13,
        name: "Rajib Das",
        deg: "PRT",
        que: "",
        exp: "",
        img: "/assets/faculty/Rajib Das.png",
    },
    {
        id: 14,
        name: "Dipannita Lodh",
        deg: "PRT",
        que: "",
        exp: "",
        img: "/assets/faculty/Dipannita Lodh.png",
    },
    {
        id: 15,
        name: "Rita Roy Das",
        deg: "PRT",
        que: "",
        exp: "",
        img: "/assets/faculty/.png",
    },
];

export const head = [
    {
        id: 1,
        name: "Pankaj Sarma",
        dign: "Principle",
        img: "/assets/faculty/ps.jpg",
    },
    {
        id: 2,
        name: "Bhupen Sarma",
        dign: "Joint Director",
        img: "/assets/faculty/bs.png",
    },
];

