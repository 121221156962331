
import './App.css';
import {Routes, Route} from "react-router-dom"
import Topbar from './components/topbar/Topbar';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import Courses from './pages/courses/Courses';
import Faculty from './pages/faculty/Faculty';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Admission from './pages/admission/Admission';
import Footer from './components/footer/Footer';
import Gallery from './pages/gallery/Gallery';
import Eschool from './pages/eschool/Eschool';
import Aschool from './pages/aschool/Aschool';
import Developer from './components/developer/Developer';
import Fee from './pages/fee/Fee';

function App() {
  return (
    <>
    <div className='main'>
      <Topbar/>

      <div className='mainNavbar'>
        <Navbar/>
      </div>

      <div className='mainDetails'>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/courses' element={<Courses/>}/>
        <Route path='/faculty' element={<Faculty/>}/>
        <Route path='/fee' element={<Fee/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/admission' element={<Admission/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/eschool' element={<Eschool/>}/>
        <Route path='/aschool' element={<Aschool/>}/>
      </Routes>
      </div>

      <Footer/>
      
    </div>
    <Developer/>
    </>
  );
}

export default App;
