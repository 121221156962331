import React from "react";
import "./Fee.css";
const PDF_FILE_URL = "http://13.126.44.161/Admission Fee College.pdf";

function Fee() {
  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  return (
    <>
      <div className="fee">
        <div className="feeT">Courses & Fees Structure</div>
        <div className="feeTS">Academic Session 2023-24</div>
        <div className="pdf">
          <span className="admis">
            SRINIVASA RAMANUJAN ACADEMY Junior College Fees Structure
          </span>

          <div className="pdfButton">
            <button
              type="button"
              class="btn btn-outline-success"
              onClick={() => {
                downloadFileAtURL(PDF_FILE_URL);
              }}
            >
              Download here
            </button>
          </div>
        </div>
        <div>
          <h5>Contact us for help</h5>
        </div>
      </div>
    </>
  );
}

export default Fee;
