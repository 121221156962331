import "./Faculty.css";
import { teacherCollege, head, teacherSchool } from "../../data";

export default function Faculty() {
  return (
    <>
      <div className="faculty">
        <div className="facultyTag">Faculty & Staff</div>

        {head.map((val) => (
          <div className="princ">
            <img className="headImg" src={val.img} alt="" />
            <h6>{val.name}</h6>
            <h5>{val.dign}</h5>
          </div>
        ))}

        <hr/>

        <div className="facultyDetails">
          <h2>SRINIVASA RAMANUJAN ACADEMY JUNIOR COLLEGE </h2>
          
          <div className="container">
          {teacherCollege.map((val) => (
            <div className="princ">
              <img className="headImg" src={val.img} alt="" />
              <div>
                <h5>{val.name}</h5>
                <h6>{val.deg}</h6>
                <h6>{val.que}</h6>
                <h6>{val.exp}</h6>
              </div>
            </div>
          ))}
          </div>
            
            <hr/>

          <div className="facultyDetailsD">
          <h2>SRINIVASA RAMANUJAN ACADEMY SCHOOL </h2>
          <div className="container">
          {teacherSchool.map((val) => (
            <div className="princ">
              <img className="headImg" src={val.img} alt="" />
              <div>
                <h5>{val.name}</h5>
                <h6>{val.deg}</h6>
                <h6>{val.que}</h6>
                <h6>{val.exp}</h6>
              </div>
            </div>
          ))}
          </div>
          </div>

        </div>
      </div>
    </>
  );
}
