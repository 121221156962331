import { Link } from "react-router-dom";
import "./Navbar.css";

export default function Navbar() {
  return (
    <>
      <header>
        <nav className="navbar">
            <div className="navbarWrapper">
                <ul className="navbarItems">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/courses">Courses</Link></li>
                    <li><Link to="/faculty">Faculty & Staff</Link></li>
                    <li><Link to="/gallery">Gallery</Link></li>
                    <li><Link to="/fee">Fees</Link></li>
                    <li><Link to="/about">About us</Link></li>
                    <li><Link to="/contact">Contact us</Link></li>
                </ul>

                <div className="adimission">
                  <Link to="/admission" style={{ textDecoration: 'none' }}>
                      <div className="getAdmission">GET ADMISSION</div>
                  </Link>
                </div>

            </div>
        </nav>
      </header>
    </>
  )
}
