import React from 'react'
import "./Developer.css"

const Developer = () => {
  return (
    <>
        <div className='developer'>
            <span className='developerT'>Design & Developed by <a href='https://www.hnbgroup.in/' target="_blank" className='developerT'>www.hnbgroup.in</a></span>            
        </div>
    </>
  )
}

export default Developer